.form-check:not(.form-switch) {
  .form-check-input[type="checkbox"] {
    &:checked {
      background-size: 65%;
    }
  }

  .form-check-input {
    &:disabled:not(:checked) {
      background-color: $input-disabled-bg;
      border-color: $input-disabled-bg;
      opacity: 1;
    }
  }
}

.form-check-input {
  &:not(:disabled) {
    &:checked {
      box-shadow: 0 2px 4px 0 rgba($form-check-input-checked-bg-color, 0.4);
    }

    &:indeterminate {
      background-size: 85%;
    }
  }
}

/* Switches */

.form-switch {
  position: relative;

  .form-check-input {
    height: $form-switch-height;

    &:checked {
      box-shadow: none !important;
    }
  }

  .form-check-input {
    border: none;

    &:not(:checked) {
      background-color: $switch-bg-color;
    }

    &:active {
      filter: none;
    }

    &:not(:disabled) {
      &:checked,
      &:focus {
        box-shadow: none !important;
      }
    }
  }

  // }
  .form-check-label {
    line-height: $form-switch-height;

    /*For Switch text*/
    .switch-text-left,
    .switch-text-right,
    .switch-icon-left,
    .switch-icon-right {
      position: absolute;
      user-select: none;
      line-height: 1.65;

      i,
      svg {
        height: 13px;
        width: 13px;
        font-size: 13px;
      }
    }

    .switch-text-left,
    .switch-icon-left {
      left: 6px;
      top: 0;
      color: $white;
      opacity: 0;
      transform: translateX(8px);
      transition: opacity 0.1s ease, transform 0.15s ease;
    }

    .switch-text-right,
    .switch-icon-right {
      left: 25px;
      top: 0;
      opacity: 1;
      transform: translateX(0px);
      transition: opacity 0.08s ease, transform 0.15s ease;
    }

    &:focus {
      outline: 0;
    }
  }

  // /*For Switch label*/
  // after its checked

  .form-check-input:checked ~ .form-check-label::before {
    box-shadow: none;
  }

  /*For Switch Handle Animation*/
  .form-check-input:checked ~ .form-check-label::after {
    transform: translateX(1.4rem);
  }

  .form-check-input:checked ~ .form-check-label {
    .switch-text-left,
    .switch-icon-left {
      transform: translateX(0);
      opacity: 1;
    }

    .switch-text-right,
    .switch-icon-right {
      transform: translateX(-8px);
      opacity: 0;
    }
  }

  .form-check-input:not(:checked) ~ .form-check-label {
    .switch-text-left {
      opacity: 0;
    }

    .switch-text-right {
      opacity: 1;
    }
  }

  .form-check-input:checked ~ .form-check-label {
    .switch-text-right {
      opacity: 0;
    }

    .switch-text-left {
      opacity: 1;
    }
  }
}
